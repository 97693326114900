// Django helpers

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}
$.ajaxSetup({
    beforeSend: function(xhr, settings) {
        if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader("X-CSRFToken", $("[name=csrfmiddlewaretoken]").val());
        }
    }
});


// Utility functions

function serializeForm(el) {
  // Return an object containing all values in the form
  // The reason we can't just use jQuery's `serializeArray` for this is that
  // it omits unchecked checkboxes
  var checkboxValues, otherValues, values;

  checkboxValues = $('input[type=checkbox]', el).map(function() {
    return {name: this.name, value: this.checked ? '1' : ''};  // Django expects '1' if true and '' if false in the GET params
  }).get();

  otherValues = $('input,select', el).not(':checkbox').serializeArray();

  values = checkboxValues.concat(otherValues);

  return values.reduce(function(acc, cur) {
      acc[cur.name] = cur.value;
      return acc;
    }, {});
}

function getChangedParams(params) {
  // Compare the preset's parameters to the form's paramters to see which
  // ones have changed and return an object containing all that are different
  var changedParams;

  changedParams = {};

  for (var key in params) {
    if(params[key] != presetParams[key]) {  // TODO: presetParams is outside scope
      changedParams[key] = params[key];
    }
  }

  return changedParams;
}

// Component initialization

function copyToClipboard(str) {
  var el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

//

function handleImageError(imgElement) {
  // Set a default or alternative image source
  imgElement.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';

}

function initCopyToClipboard() {
    // We want an easy way for the user to copy embed-codes to their clipboard
    // by clicking a button
    $('.copy-to-clipboard').on('click', function(event) {
        event.preventDefault();

        copyToClipboard($(this).data('clipboard'));

        $.NotificationApp.send('Copied!', undefined, 'top-right');
    });
}


function initGenericModal(context) {
    // Sometimes we want to use modals for confirmation or other quick dialogs
    // Our preferred way of achieving this is to load the page we want to show
    // into an empty modal that's included in the base-template.
    // Example: <a href="foo.html" class="load-generic-modal">bar</a>
    var modal = $('#generic-modal');

    $('.load-generic-modal', context).on('click', function(event) {
      event.preventDefault();

      modal.load($(this).attr('href'), function() {
            // https://select2.org/troubleshooting/common-problems#select2-does-not-function-properly-when-i-use-it-inside-a-bootst
            $('[data-toggle="select2"]', modal).select2({dropdownParent: modal}).each(function(index) {
              $(this).data('select2').$selection.addClass('form-control');

              // we would like to maintain the order of selected options
              $(this).on('select2:select', function(e){
                var id = e.params.data.id;
                var option = $(e.target).children('[value='+id+']');
                option.detach();
                $(e.target).append(option).change();
              });
            });
            modal.modal('show');
        });
    });
}

function initGenericPanel(context) {
  var href = null;
  var panel = $('#generic-panel');
  $('.load-generic-panel', context).on('click', function(event) {
    event.preventDefault();
    href = $(this).attr('href');
    var cardType = $(this).parent().data('card-type');
    $('.card.' + cardType).removeClass('bg-primary highlighted').addClass('bg-light');
    $('.card.' + cardType).children().removeClass('text-white').addClass('text-dark');
    $(this).removeClass('text-dark').addClass('text-white');
    $(this).parent().removeClass('bg-light').addClass('bg-primary highlighted');
    reloadPanel();
    // $.get(href, function(data) {
    //   panel.html(data);
    //   self.initGenericModal(panel);
    // });
  });
  panel.on('reload', function() {
    reloadPanel()
  });

  function reloadPanel() {
    $.get(href, function(data) {
      panel.html(data);
      self.initGenericModal(panel);
    });
  }
}


function initCustomFileInputs() {
  // We've got pretty custom file-inputs, but their labels don't change when
  // a new file is selected
  $(document).on('change', '.custom-file-input', function(e) {
    var input = $(e.target);
    $('label[for=' + input.attr('id') + ']').text(input.val().split('\\').pop().split('/').pop());
  });
}


function serializedArrayToObject(obj, tuple) {
  obj[tuple['name']] = tuple['value'];
  return obj;
}


function initSelectInsteadOfTabs() {
  $('.selectInsteadOfTabs').on('change', function (e) {
    $('#' + $(this).val()).addClass('active').siblings().removeClass('active');
  });
}

function initSideMenu() {
  // $("#menu").metisMenu({
  //   triggerElement: '.side-nav-title',
  //   toggle: false
  // });
  // $('.slimscroll-menu').slimscroll({
  //   height: 'auto',
  //   position: 'right',
  //   size: "8px",
  //   color: '#9ea5ab',
  //   wheelStep: 5,
  //   touchScrollStep: 20
  // });
}

/**
 * Start a polling task with a POST request to the given url and poll the result.
 * The callback onReady is called with the result when the task is ready.
 *
 * @param url the url where to POST and GET to start and poll the task
 *            POST (data), returns {task_id: string}
 *            GET ?task_id=string, returns {ready: boolean, ...}
 * @param onReady the callback to call when the task is ready
 * @param data (optional) data for the POST request
 * @param pollInterval (optional) interval in milliseconds to poll the task, default is 2000
 */
function startPollingTask(url, onReady, data, pollInterval) {
    $.post(url, data || {}, function(response) {
        if (response.task_id) {
            pollResult(url, response.task_id, onReady, pollInterval);
        } else {
            console.error(response);
        }
    });
}

function pollResult(url, taskId, onReady, pollInterval) {
    $.get(url, {task_id: taskId}, function(response) {
        if (response.ready) {
            onReady(response);
        } else {
            setTimeout(function() {
                pollResult(url, taskId, onReady, pollInterval);
            }, pollInterval || 2000);
        }
    });
}

$(document).ready(function() {
    initCopyToClipboard();
    initGenericModal(document);
    initGenericPanel(document);
    initCustomFileInputs();
    initSelectInsteadOfTabs();
    initSideMenu();
    // initSearchFilter();
});
